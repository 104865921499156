<template>
    <div class="content">

        <div class="page-info">
            <van-cell-group>
                <van-field v-model="params.name" label="名称" placeholder="请输入名称" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.phone" label="联系方式" placeholder="请输入联系方式" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.address" label="地址" placeholder="请输入地址" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.remark" label="备注" placeholder="请输入备注" />
            </van-cell-group>
        </div>

        <div class="list">

            <van-form @submit="submit">
                <div class="line" v-for="item in list">
                    <van-field
                            v-model="item.name"
                            name="商品"
                            label="商品"
                            placeholder="商品"
                            maxlength="7"
                    />
                    <van-field
                            v-model="item.unitPrice"
                            type="number"
                            name="单价"
                            label="单价"
                            placeholder="单价(例子：12.0)"
                            maxlength="5"
                            @input="setunitPrice(item)"
                    />
                    <van-field
                            v-model="item.quantity"
                            type="digit"
                            name="数量"
                            label="数量"
                            placeholder="数量"
                            maxlength="3"
                    />
                </div>
                <div class="add" @click="add">
                    +
                </div>
                <div style="margin: 16px;" v-if="!id">
                    <van-button round block type="info" native-type="submit">
                        打印
                    </van-button>
                </div>
            </van-form>
        </div>

        <div style="margin: 16px;" v-if="!!id">
            <van-button round block type="info" @click="reset">
                再次打印
            </van-button>
        </div>

    </div>
</template>
<script>
    export default {
        name: "print",
        data() {
            return {
                list: [
                    {
                        name: '',
                        unitPrice: '',
                        quantity: '',
                        amount: ''
                    }
                ],
                params: {
                    name: "聚合商圈",
                    phone: "13813265383",
                    address: "徐州沛县",
                    remark: ""
                },
                id: null
            }
        },
        components: {

        },
        created() {

        },
        mounted() {

        },
        methods: {
            setunitPrice(obj) {
                if (obj.unitPrice.length >=5 && obj.unitPrice.indexOf('.') <0){
                    obj.unitPrice = obj.unitPrice.slice(0,3) + '.0'
                }else {

                }
                this.$forceUpdate()
            },
            reset() {
                this.list = [
                    {
                        name: '',
                        unitPrice: '',
                        quantity: '',
                        amount: ''
                    }
                ]
                this.id = null
            },
            add() {
              this.list.push({
                  name: '',
                  unitPrice: '',
                  quantity: '',
                  amount: 0
              })
            },
            submit() {

                if (!!this.id){
                    this.$toast("请不要重复打印")
                    return false
                }

                let flag = false
                this.list.forEach(item=>{
                    if (!item.name || !item.unitPrice || !item.quantity){
                        flag = true
                    }
                })
                if (flag){
                    this.$toast("请输入完整")
                    return false
                }
                this.list.forEach(item=>{
                    // item.unitPrice = (Math.round(item.unitPrice * 10) / 10).toFixed(1)
                    item.quantity = parseInt(Math.round(item.quantity * 10) / 10)
                    item.amount = (Math.round((Number(item.unitPrice) * Number(item.quantity)) * 10) / 10).toFixed(1)
                })

                let params = this.params
                params.list = this.list
                console.log(params)
                this.$util.ajax('/jh/printer/print', 'POST', params).then(res => {

                    if (res.code === 200){
                        this.id = res.data.id
                        this.$Toast("已打印")
                    }

                    console.log(res)
                    // this.trade_no = res.data.trade_no
                    // this.alipay()
                    // console.log(res)
                    // if (!!res.openid) {
                    //     localStorage.user_id = res.user_id
                    //     this.user_id = res.user_id
                    // }
                })

                console.log(this.list)


            }
        }
    }
</script>

<style scoped lang="less">
    .content {
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        padding: 0.32rem 0.32rem;
        background: #f0f0f0;
        .page-info {
            padding-bottom: 0.32rem;
        }
        .list {
            .line {
                padding-bottom: 0.32rem;
            }
            .add {
                font-size: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
            }
        }
    }
</style>